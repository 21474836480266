const Menuitems = [
  {
    navlabel: true,
    subheader: "Menu",
  },

  {
    id: 1,
    title: "Charts",
    href: "/",
  },
  {
    id: 3,
    title: "Old",
    href: "/old",
  }
];

export default Menuitems;
