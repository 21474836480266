import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { ResponsiveLine } from '@nivo/line';

import { useStore } from "@nanostores/react";
import { $selected_date } from "../../utils/store";

const VerticalLineAnnotation = ({ xScale, yScale, diff_days }) => {

    if (!diff_days) {
        return;
    }

    const x = xScale(diff_days);
    const y = yScale(0);
    // const y = height - 100;
    return (
        <line
            x1={x}
            x2={x}
            y1={0}
            y2={y}
            stroke="blue" // Цвет линии
            strokeWidth={2} // Толщина линии
            strokeDasharray="4 2" // Пунктирная линия (опционально)
            style={{ zIndex: 1 }}
        />
    );
};

function Line({ data, end_date }) {
    const selected_date = useStore($selected_date);
    console.log(selected_date);


    const xTickValues = [];
    const xTickLabels = [];

    let max_days = 0;
    data.map(item => {
        max_days = max_days < item?.data?.length ? item.data.length : max_days;
    });

    const date_start = end_date?.subtract(max_days - 1, 'day');

    let currentDate = dayjs(date_start);
    let currentDateIndex = 0;

    while (currentDate.isBefore(end_date) || currentDate.isSame(end_date)) {
        if (currentDate.date() == 1) {
            xTickValues.push(currentDateIndex);
            xTickLabels.push(currentDate.format('MMM'));
        }
        else if (!currentDateIndex && currentDate.date() < 15) {
            xTickValues.push(currentDateIndex);
            xTickLabels.push(currentDate.format('MMM'));
        }
        currentDate = currentDate.add(1, 'day');
        currentDateIndex += 1;
    }

    let diff_days = 0;
    if (selected_date) {
        diff_days = max_days - end_date.diff(dayjs(selected_date), 'day');
    }


    return (
        <ResponsiveLine
            data={data}
            layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends',
                ({ xScale, yScale }) => VerticalLineAnnotation({ xScale, yScale, diff_days }) // Кастомный слой должен быть в конце
            ]}
            lineWidth={1}
            margin={{ top: 0, right: 10, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickValues: xTickValues,
                format: (value) => {
                    const index = xTickValues.indexOf(value);
                    return index >= 0 ? xTickLabels[index] : '';
                },
                legendOffset: -12,
                legendPosition: 'middle',
            }}
            gridXValues={xTickValues}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'count',
                legendOffset: -40,
                legendPosition: 'middle',
                truncateTickAt: 0
            }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="data.yFormatted"
            pointLabelYOffset={-12}
            enableTouchCrosshair={true}
            enablePoints={false}
            useMesh={true}
            legends={[
                {
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 0,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />
    );
}

export default Line;