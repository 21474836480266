import React, { useState } from 'react';

function replaceNonDigitsWithChar(str: string, replacementChar = ' ') {
    return str.replace(/\D/g, replacementChar).replace(/\s+/g, ' ').trim().replaceAll(' ', ',');
}

function Home() {
    const url = 'https://mpstats.alloff.space/plot/';
    const [plot, setPlot] = useState('');

    const [text, setText] = useState('');
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    };

    const update = () => {
        const skus = replaceNonDigitsWithChar(text);
        setPlot(`${url}?skus=${skus}`)
    }

    return (
        <div className="App">
            <div className="Settings">
                <textarea
                    value={text}
                    onChange={handleChange}
                    className="Textarea"
                    rows={15}
                />
                <button onClick={update}>Update</button>
            </div>
            <div className="Plot">
                <iframe src={plot}></iframe>
            </div>
        </div>
    );
}

export default Home;