import React, { useState } from 'react';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { useStore } from "@nanostores/react";
import { $skus } from "../../utils/store";

function replaceNonDigitsWithChar(str, replacementChar = ' ') {
    return str.replace(/\D/g, replacementChar).replace(/\s+/g, ' ').trim().split(' ');
}

function AddItems() {

    const items = useStore($skus);
    const [inputValue, setInputValue] = useState('');

    const handleAddItem = () => {
        if (inputValue.trim() !== '') {
            $skus.set([...items, inputValue]);
            setInputValue('');
        }
    };

    const handleChange = (event) => {
        $skus.set(replaceNonDigitsWithChar(event.target.value));
    };

    return (
        <Paper
            elevation={3}
            style={{ padding: '20px', maxWidth: '400px', margin: '20px auto', borderRadius: '10px' }}
        >
            <TextField
                label="Skus"
                multiline
                onChange={handleChange}
                rows={4} // количество строк по умолчанию
                variant="outlined" // или "filled" / "standard"
                fullWidth
            />
        </Paper>
    );
}

export default AddItems;
