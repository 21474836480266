import dayjs from 'dayjs';

import { $skus_chart, $smoothed_chart, $result_chart, $result_end_date, $date_start, $date_end, $loading, $skus } from "./store";
import { dataFormat } from "./utils";

export const getData = async () => {
    try {
        $result_chart.set(null);
        $skus_chart.set(null);
        $smoothed_chart.set(null);

        $loading.set(true);
        const d1 = $date_start.get()?.format('DD.MM.YYYY');
        const d2 = $date_end.get()?.format('DD.MM.YYYY');

        const params = {
            skus: $skus.get().join(','),
            d1,
            d2
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `https://mpstats.alloff.space/api/skus?${queryString}`;

        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();

        $result_end_date.set(dayjs(result.d2, 'DD.MM.YYYY'));
        const data = [];
        if (result?.data_smoothed_first) {
            result.data_smoothed_first.map(data_smoothed => {
                data.push({ id: data_smoothed.sku, data: dataFormat(data_smoothed.data) });
            })
        }
        $smoothed_chart.set(data);

        const skus = [];
        if (result?.skus) {
            for (const sku in result.skus) {
                const data = result.skus[sku]?.smoothed_data || [];
                skus.push({ id: sku, data: dataFormat(data) });
            }
        }
        $skus_chart.set(skus);

        if (result?.smoothed_data && result?.smoothed_data_2) {
            $result_chart.set([
                { id: 'По данным', data: dataFormat(result.smoothed_data) },
                { id: 'Прогнозируемый', data: dataFormat(result.smoothed_data_2) }
            ]);
        }

        $loading.set(false);
        return result;
    } catch (error) {
        $loading.set(false);
    }

    return null;
};