import { atom, action } from "nanostores";
import dayjs from 'dayjs';
// import { getCart, removeCoupon, validateCoupon } from "./api";

const persistentAtom = (name, initial = undefined, opts = {}) => {
    let encode = opts.encode || JSON.stringify
    let decode = opts.decode || JSON.parse

    let store = atom(initial)

    store.isLocalStorage = true;

    let set = store.set
    store.set = newValue => {

        if (typeof newValue === 'undefined') {
            delete localStorage[name]
        } else {
            localStorage[name] = encode(newValue)
        }
        set(newValue)
    }

    store.restore = () => {
        store.set(localStorage[name] ? decode(localStorage[name]) : initial)
    }

    if (!store.loaded) {
        store.restore();
        store.loaded = true;
    }
    return store
}


// export const $isOrderSuccessful = persistentAtom('orderSuccessful', undefined);

export const $loading = atom(false);

export const $date_start = atom(dayjs().subtract(1, 'year'));
export const $date_end = atom(dayjs());

export const $selected_date = persistentAtom('selected_date', null);

export const $skus_chart = atom(null);
export const $smoothed_chart = atom(null);
export const $result_chart = atom(null);
export const $result_end_date = atom(null);

export const $skus = atom([]);

// export const updateCount = () => {
//     $count.set($count.get() + 1);
// }
