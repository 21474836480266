import React, { useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker as DatePickerMui } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';

function DatePicker({ label = '', value, onChange }) {
    const [selectedDate, setSelectedDate] = useState(dayjs());
    //dayjs().subtract(1, 'year')

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {!!label && <span>{label}</span>}
            <div>
                <DatePickerMui
                    value={dayjs(value)}
                    onChange={onChange}
                    format="DD/MM/YYYY" // Изменяем формат на месяц/день/год
                    renderInput={(params) => <TextField {...params} />}
                    maxDate={dayjs()} // Ограничение до текущей даты
                />
            </div>
        </LocalizationProvider>
    );
}

export default DatePicker;