import React, { useState, useEffect } from 'react';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';

import DatePicker from "../parts/datepicker";
import AddItems from "../parts/add_items";
import Textarea from "../parts/textarea";

import { useStore } from "@nanostores/react";
import { $date_start, $date_end, $selected_date } from "../../utils/store";
import { getData } from "../../utils/api";


function Options() {
    const date_start = useStore($date_start);
    const date_end = useStore($date_end);
    const selected_date = useStore($selected_date);

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const onClickUpdate = () => {
        getData()
    };

    return (
        <>
            <div className='px-[12px]'>
                <DatePicker label="from:" value={date_start} onChange={$date_start.set} />
                <DatePicker label="to:" value={date_end} onChange={$date_end.set} />
                <DatePicker label="select date:" value={selected_date} onChange={$selected_date.set} />
            </div>
            {false &&
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChange}
                            name="exampleCheckbox"
                            color="primary"
                        />
                    }
                    label="Hide single charts"
                />
            }
            {false && <AddItems />}
            <Textarea />
            <div className='flex justify-center pb-[12px]'>
                <Button variant="contained" color="secondary" onClick={onClickUpdate}>Update</Button>
            </div>
        </>
    );
}

export default Options;