import { useMediaQuery, Box, Drawer } from "@mui/material";
import { Sidebar } from 'react-mui-sidebar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import SidebarItems from "./SidebarItems";
import Options from "../charts/options";

const MSidebar = ({
  isMobileSidebarOpen,
  onSidebarClose,
  isSidebarOpen,
}) => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const sidebarWidth = "270px";

  // Custom CSS for short scrollbar
  const scrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '7px',

    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#eff2f7',
      borderRadius: '15px',
    },
  };


  if (lgUp) {
    return (
      <Box
        sx={{
          width: sidebarWidth,
          flexShrink: 0,
        }}
      >
        <Drawer
          anchor="left"
          open={isSidebarOpen}
          variant="permanent"
          PaperProps={{
            sx: {
              boxSizing: "border-box",
              ...scrollbarStyles,
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
            }}
          >
            <Sidebar
              width={'270px'}
              collapsewidth="80px"
              open={isSidebarOpen}
              themeColor="#5d87ff"
              themeSecondaryColor="#49beff"
              showProfile={false}
            >
              <Box>
                <SidebarItems />
              </Box>
              <Router >
                <Routes>
                  <Route path="/" element={
                    <Box>
                      <Options />
                    </Box>
                  } />
                </Routes>
              </Router>

            </Sidebar >
          </Box>
        </Drawer>
      </Box>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      variant="temporary"
      PaperProps={{
        sx: {
          boxShadow: (theme) => theme.shadows[8],
          ...scrollbarStyles,
        },
      }}
    >
      <Box px={2}>
        <Sidebar
          width={'270px'}
          collapsewidth="80px"
          isCollapse={false}
          mode="light"
          direction="ltr"
          themeColor="#5d87ff"
          themeSecondaryColor="#49beff"
          showProfile={false}
        >
          <SidebarItems />
          <Router >
            <Routes>
              <Route path="/" element={
                <Box>
                  <Options />
                </Box>
              } />
            </Routes>
          </Router>
        </Sidebar>
      </Box>

    </Drawer>
  );
};

export default MSidebar;





