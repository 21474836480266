import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { CircularProgress } from '@mui/material';

import Line from "../components/charts/line";

import { useStore } from "@nanostores/react";
import { $skus_chart, $smoothed_chart, $result_chart, $result_end_date, $loading } from "../utils/store";

import { getData } from "../utils/api";

const dataFormat = (array) => {
    return array.map((y, index) => { return { x: index, y: parseInt(y) } });
}

function Charts() {
    dayjs.extend(customParseFormat);

    const skus = useStore($skus_chart);
    const data = useStore($smoothed_chart);
    const result = useStore($result_chart);
    const end_date = useStore($result_end_date);
    const loading = useStore($loading);

    useEffect(() => {
        // getData();

        return () => {
            // Здесь можно выполнить очистку, если компонент размонтируется
        };
    }, []);

    if (loading) {
        return <div className='w-full h-[80vh] max-h-full flex justify-center items-center'>
            <CircularProgress />
        </div>
    }

    return (
        <div className="App flex-wrap">
            {!!skus && !!skus.length &&
                skus.map(data =>
                    <div className='w-1/2'>
                        <div className='w-full aspect-w-2 aspect-h-1 relative'>
                            <div className='absolute top-0 left-0 w-full h-full'>
                                <Line
                                    data={[data]}
                                    end_date={end_date}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
            {!!data && !!data.length &&
                <div className='w-1/2'>
                    <div className='w-full aspect-w-2 aspect-h-1 relative'>
                        <div className='absolute top-0 left-0 w-full h-full'>
                            <Line
                                data={data}
                                end_date={end_date}
                            />
                        </div>
                    </div>
                </div>
            }
            {!!result && !!result.length &&
                <div className='w-1/2'>
                    <div className='w-full aspect-w-2 aspect-h-1 relative'>
                        <div className='absolute top-0 left-0 w-full h-full'>
                            <Line
                                data={result}
                                end_date={end_date}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Charts;